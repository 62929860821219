import React, { useMemo } from 'react';
import { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FreeBusyStatus, RecentDayNotes } from "../../../../../services/WhereaboutOptions";
import { updateMovement } from "../../../../../store/ducks/editMovements.duck";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { MovementImage } from "../../../atoms/MovementImage";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";
import { BodyRegular, BodySmall, BodyVerySmall } from "../../../atoms/fonts/Body";
import Colours from "../../../atoms/Colours";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { addNewNote, selectRecentDayNotes } from "../../../../../store/ducks/dayNotes.duck";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Pill } from "../../../atoms/Pill";

export function DayNotesActivityItem(props: Props) {
  const {activeDay, iconPack, activity, activeUserId} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const recentDayNotes = useSelector(selectRecentDayNotes);

  const limitedRecentDayNotes = useMemo(() => {
    if (recentDayNotes.length > 3) {
      return recentDayNotes.slice(0, 3);
    }
    return recentDayNotes;
  }, [recentDayNotes])

  const updateSelectedDay = (e: any) => {
    e.stopPropagation();
    dispatch(openDialogWithPayload({
      payload: { onSaveClicked: onNoteEnteredOrSelected },
      activeDialog: DialogIdentifiers.DayNotesEntryDialog
    }))
  }

  const onNoteEnteredOrSelected = (note: string) => {
    dispatch(updateMovement({selectedOption: activity, locationId: 0, activeDay: activeDay?.format(DATE_FORMAT), activeUserId, dayNote: note}));
    dispatch(addNewNote({noteToAdd: note, optionId: activity.id}));
  }

  const activitySelected = (e: any) => {
    e.stopPropagation();
    dispatch(updateMovement({selectedOption: activity, locationId: 0, activeDay: activeDay?.format(DATE_FORMAT), activeUserId}));
  }

  return (<Container className="activity" onClick={(e: any) => activitySelected(e)}>
    <ActivityTitleSection>
      <MovementImage src={iconPackPath(iconPack, activity.image, FreeBusyStatus.FREE)} alt={activity.alt}/>
      <BodyRegular weight={600}>{activity.label}</BodyRegular>
    </ActivityTitleSection>
    {props?.activity?.isPrivate && <BodyVerySmall colour={Colours.mildGrey} title={'This is only visible to you, line managers, and company admins.'}>Private</BodyVerySmall>}
    <DayNotesListContainer>
      <RecentNotesContainer>
        {limitedRecentDayNotes.map((dayNote: RecentDayNotes, key: number) => (<>
            <DayNotePill key={key} onClick={() => onNoteEnteredOrSelected(dayNote.note)} title={dayNote.note}>
              <BodySmall>{dayNote.note}</BodySmall>
            </DayNotePill>
          </>
        ))}
      </RecentNotesContainer>
      <AddNoteButton onClick={(e: any) => updateSelectedDay(e)}
                     colour={Colours.darkGrey}
                     weight={600}>
        {t('day-notes-dialog.add-note')}
      </AddNoteButton>
    </DayNotesListContainer>
  </Container>)
}

interface Props {
  activeDay?: Moment;
  iconPack: any;
  activity: any;
  activeUserId?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ActivityTitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const DayNotesListContainer = styled.div`
  max-height: 36px;
  display: flex;
  width: 100%;
  button {
    width: 100px;
  }
`

export const RecentNotesContainer = styled.div`
  display: flex;
  max-width: 75%;
  overflow: hidden;
`

const AddNoteButton = styled<any>(BodySmall)`
  border: 1px solid ${Colours.mildGrey};
  border-radius: 4px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const DayNotePill = styled<any>(Pill)<any>`
  cursor: pointer;
  margin-right: 8px;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    background-color: ${Colours.lightGrey};
  }
`
