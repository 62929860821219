import { DashboardState, initialDashboardState } from '../ducks/dashboard.duck';
import { AuthState, initialAuthState } from '../ducks/auth.duck';
import { EditMovementsState, initialEditMovementsState } from '../ducks/editMovements.duck';
import { AddMemberDialogState, initialAddMemberState } from '../ducks/addMemberDialog.duck';
import { ForgotPasswordState, initialForgotPasswordState } from '../ducks/forgotPassword.duck';
import { AddTeamDialogState, initialAddTeamDialogState } from '../ducks/addTeamDialog.duck';
import { initialRegistrationState, RegistrationState } from '../ducks/registration.duck';
import { initialOnboardingState, OnboardingState } from '../ducks/onboarding.duck';
import { initialManagementState, ManagementState } from '../ducks/management.duck';
import { initialNotificationState, NotificationState } from '../ducks/notification.duck';
import { GeneralState, initialGeneralState } from "../ducks/general.duck";
import { CompanyMovementsState, initialCompanyMovementsState } from "../ducks/companyMovements.duck";
import { initialSetupCompanyState, SetupCompanyState } from "../ducks/setupCompany.duck";
import { initialLoginPageState, LoginPageState } from '../../components/pages/login/login.duck';
import {
  initialPermissionNeededDialogState,
  PermissionNeededDialogState
} from "../../components/dialogs/permission-needed-dialog/permissionNeeded.duck";
import {
  DeleteTeamDialogState,
  initialDeleteTeamDialogState
} from "../../components/dialogs/delete-team-dialog/deleteTeamDialog.duck";
import {
  DeleteCompanyDialogState,
  initialDeleteCompanyDialogState
} from "../../components/dialogs/delete-company-dialog/deleteCompanyDialog.duck";
import { ConfigState, initialConfigState } from "../ducks/config.duck";
import {
  EditCompanyState,
  initialEditCompanyState
} from "../../components/pages/settings/pages/edit-company-page/editCompany.duck";
import { HolidayBookingState, initialHolidayBooking } from "../ducks/holidayBooking.duck";
import { HolidaysState, initialHolidays } from "../ducks/holidays.duck";
import { CompanyStructureState, initialCompanyStructure } from "../ducks/companyStructure.duck";
import { initialOutlookSync, OutlookSync } from "../ducks/outlookSync.duck";
import { initialPaymentsState, PaymentsState } from "../ducks/payments.duck";
import { initialWallPlannerState, WallPlannerState } from "../../components/pages/wall-planner/wallPlanner.duck";
import { DialogState, initialDialogState } from "../ducks/dialog.duck";
import { AdvanceHotDeskingSetupState, initialAdvanceHotDeskingSetupState } from "../ducks/advanceHotDeskingSetup.duck";
import { DeskReservationState, initialDeskReservation } from "../ducks/deskReservation.duck";
import { initialOfficeUsageState, OfficeUsageState } from "../ducks/officeUsage.duck";
import { EditOfficeLayout, initialEditOfficeLayout } from "../ducks/editOfficeLayout.duck";
import { initialWeeklyDeskAvailability, WeeklyDeskAvailability } from "../ducks/weeklyDeskAvailability.duck";
import { initialOfficeViewState, OfficeViewState } from "../ducks/officeView.duck";
import { DeskBookingState, initialDeskBookingState } from "../ducks/deskBooking.duck";
import { EditUserState, initialEditUserState } from '../ducks/editUser.duck';
import { ApprovalRequestsState, initialApprovalRequestsState } from "../ducks/approvalRequests.duck";
import { initialUserTags, UserTags } from "../ducks/userTags.duck";
import { initialUserManagementState, UserManagementState } from "../ducks/userManagement.duck";
import { initialWhereaboutsOptionsState, WhereaboutsOptionsState } from "../ducks/whereaboutsOptions.duck";
import { HolidaysV2State, initialHolidaysV2State } from "../ducks/holidays-v2.duck";
import { EditDeskState, initialEditDesk } from "../ducks/editDesk.duck";
import { FollowingTeamsAndTagsState, initialFollowingTeamsAndTagsState } from "../ducks/followingTeamsAndTags.duck";
import {
  initialKeyResponsibilitiesReportingState,
  KeyResponsibilitiesReportingState
} from "../../components/pages/reporting/key-responsibilities-report/key-responsibilities.duck";
import { initialRoomFinderState, RoomFinderState } from "../../components/pages/meeting-rooms/models/room-finder.duck";
import {
  initialMeetingRoomState,
  MeetingRoomState
} from "../../components/pages/meeting-rooms/models/meeting-room.duck";
import {
  initialMeetingRoomSettingsState,
  MeetingRoomSettingsState
} from "../../components/pages/settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import {
  initialManageUsersState,
  ManageUsersState
} from "../../components/pages/settings/pages/manage-users-page-v2/ducks/manage-users.duck";
import {
  initialManageDesksState,
  ManageDesksState
} from "../../components/pages/company-movements/ducks/manageDesks.duck";
import {
  initialTeamMovementsState,
  TeamMovementsState
} from "../../components/pages/team-movements/models/team-movements.duck";
import {
  initialWallPlannerHoverBoxState,
  WallPlannerHoverBoxState
} from "../../components/pages/wall-planner/data/WallPlannerHoverBox.duck";
import {
  AddUsersState,
  initialAddUsersState
} from "../../components/pages/settings/pages/manage-users-page-v2/ducks/add-users.duck";
import {
  HolidayReportsState,
  initialHolidayReportsState
} from "../../components/pages/holidays-v2/models/holiday-reports.duck";
import {
  CarParkingMapViewState,
  initialCarParkingMapViewState
} from "../../components/pages/company-movements/ducks/carParkingMapView.duck";
import {
  initialVisitorBookingState,
  VisitorBookingState
} from "../../components/pages/company-movements/components/visitor-booking/data/visitorBooking.duck";
import { DepartmentsState, initialDepartmentsState } from "../../components/pages/departments/data/departments.duck";
import { initialLineReportsWhereaboutsState, LineReportsWhereaboutsState } from "../ducks/LineReportsWhereabouts.duck";
import {
  HolidayAllowancesState,
  initialHolidayAllowancesState
} from "../../components/pages/holidays-v2/models/holiday-allowances.duck";
import { DayNotesState, initialDayNotesState } from "../ducks/dayNotes.duck";

export interface AppState {
  auth: AuthState;
  dashboard: DashboardState;
  editMovements: EditMovementsState;
  addMemberDialog: AddMemberDialogState;
  forgotPassword: ForgotPasswordState;
  addTeamDialog: AddTeamDialogState;
  registration: RegistrationState;
  onboarding: OnboardingState;
  management: ManagementState;
  notification: NotificationState;
  general: GeneralState;
  companyMovements: CompanyMovementsState;
  setupCompany: SetupCompanyState;
  loginPage: LoginPageState;
  permissionNeededDialog: PermissionNeededDialogState;
  deleteTeamDialog: DeleteTeamDialogState;
  deleteCompanyDialog: DeleteCompanyDialogState;
  config: ConfigState;
  editCompany: EditCompanyState;
  holidayBooking: HolidayBookingState;
  holidays: HolidaysState;
  companyStructure: CompanyStructureState;
  outlookSync: OutlookSync;
  payments: PaymentsState;
  wallPlanner: WallPlannerState;
  dialog: DialogState;
  advanceHotDeskingSetup: AdvanceHotDeskingSetupState;
  deskReservation: DeskReservationState;
  officeUsage: OfficeUsageState;
  editOfficeLayout: EditOfficeLayout;
  weeklyDeskAvailability: WeeklyDeskAvailability;
  officeView: OfficeViewState;
  deskBooking: DeskBookingState;
  editUser: EditUserState;
  approvalRequests: ApprovalRequestsState;
  userTags: UserTags;
  userManagement: UserManagementState;
  whereaboutsOptions: WhereaboutsOptionsState;
  holidaysV2: HolidaysV2State;
  editDesk: EditDeskState;
  followingTeamsAndTags: FollowingTeamsAndTagsState;
  keyResponsibilitiesReporting: KeyResponsibilitiesReportingState;
  roomFinder: RoomFinderState;
  meetingRoom: MeetingRoomState;
  meetingRoomSettings: MeetingRoomSettingsState;
  manageUsers: ManageUsersState;
  addUsers: AddUsersState;
  manageDesks: ManageDesksState;
  teamMovements: TeamMovementsState;
  wallPlannerHoverBox: WallPlannerHoverBoxState;
  holidayReports: HolidayReportsState;
  carParkingMapView: CarParkingMapViewState;
  visitorBooking: VisitorBookingState;
  departments: DepartmentsState;
  lineReportsWhereabouts: LineReportsWhereaboutsState;
  holidayAllowances: HolidayAllowancesState;
  dayNotes: DayNotesState;
}

export const initialAppState: AppState = {
  auth: initialAuthState,
  dashboard: initialDashboardState,
  editMovements: initialEditMovementsState,
  addMemberDialog: initialAddMemberState,
  forgotPassword: initialForgotPasswordState,
  addTeamDialog: initialAddTeamDialogState,
  registration: initialRegistrationState,
  onboarding: initialOnboardingState,
  management: initialManagementState,
  notification: initialNotificationState,
  general: initialGeneralState,
  companyMovements: initialCompanyMovementsState,
  setupCompany: initialSetupCompanyState,
  loginPage: initialLoginPageState,
  permissionNeededDialog: initialPermissionNeededDialogState,
  deleteTeamDialog: initialDeleteTeamDialogState,
  deleteCompanyDialog: initialDeleteCompanyDialogState,
  config: initialConfigState,
  editCompany: initialEditCompanyState,
  holidayBooking: initialHolidayBooking,
  holidays: initialHolidays,
  companyStructure: initialCompanyStructure,
  outlookSync: initialOutlookSync,
  payments: initialPaymentsState,
  wallPlanner: initialWallPlannerState,
  dialog: initialDialogState,
  advanceHotDeskingSetup: initialAdvanceHotDeskingSetupState,
  deskReservation: initialDeskReservation,
  officeUsage: initialOfficeUsageState,
  editOfficeLayout: initialEditOfficeLayout,
  weeklyDeskAvailability: initialWeeklyDeskAvailability,
  officeView: initialOfficeViewState,
  deskBooking: initialDeskBookingState,
  editUser: initialEditUserState,
  approvalRequests: initialApprovalRequestsState,
  userTags: initialUserTags,
  userManagement: initialUserManagementState,
  whereaboutsOptions: initialWhereaboutsOptionsState,
  holidaysV2: initialHolidaysV2State,
  editDesk: initialEditDesk,
  followingTeamsAndTags: initialFollowingTeamsAndTagsState,
  keyResponsibilitiesReporting: initialKeyResponsibilitiesReportingState,
  roomFinder: initialRoomFinderState,
  meetingRoom: initialMeetingRoomState,
  meetingRoomSettings: initialMeetingRoomSettingsState,
  manageUsers: initialManageUsersState,
  addUsers: initialAddUsersState,
  manageDesks: initialManageDesksState,
  teamMovements: initialTeamMovementsState,
  wallPlannerHoverBox: initialWallPlannerHoverBoxState,
  holidayReports: initialHolidayReportsState,
  carParkingMapView: initialCarParkingMapViewState,
  visitorBooking: initialVisitorBookingState,
  departments: initialDepartmentsState,
  lineReportsWhereabouts: initialLineReportsWhereaboutsState,
  holidayAllowances: initialHolidayAllowancesState,
  dayNotes: initialDayNotesState,
}
