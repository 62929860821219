import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { getRecentDayNotes, RecentDayNotes } from "../../services/WhereaboutOptions";

export interface DayNotesState {
  loading: boolean;
  recentDayNotes: RecentDayNotes[];
}

export const initialDayNotesState: DayNotesState = {
  loading: false,
  recentDayNotes: [],
}

export const loadRecentDayNotes: any = createAsyncThunk('dayNotes/loadRecentDayNotes',
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const optionsWithDaysNotesEnabled = state.whereaboutsOptions.whereaboutsOptions.filter(wo => wo.dayNotesEnabled);
    if (optionsWithDaysNotesEnabled.length > 0) {
      const recentDayNotes = await getRecentDayNotes();
      return recentDayNotes
    } else {
      return [];
    }
  }
);

export const addNewNote: any = createAsyncThunk('dayNotes/addNewNote',
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const recentNotes = state.dayNotes.recentDayNotes;

    if (!params.noteToAdd) {
      return recentNotes;
    }

    if (recentNotes.filter((rn) => rn.note === params.noteToAdd).length === 0) {
      return [{
        note: params.noteToAdd,
        optionId: params.optionId,
      }, ...recentNotes];
    }

    return recentNotes;
  }
);

const dayNotesSlice = createSlice({
  name: 'dayNotes',
  initialState: initialDayNotesState,
  reducers: {
  },
  extraReducers: {
    [loadRecentDayNotes.pending]: (state) => ({...state, recentDayNotes: [], loading: true}),
    [loadRecentDayNotes.failed]: (state) => ({...state, recentDayNotes: [], loading: false}),
    [loadRecentDayNotes.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      recentDayNotes: action.payload,
    }),

    [addNewNote.fulfilled]: (state, action) => ({
      ...state,
      recentDayNotes: action.payload,
    }),
  }

})

export default dayNotesSlice.reducer;

export const selectIsLoading = (state: AppState) => state.dayNotes.loading;
export const selectRecentDayNotes = (state: AppState) => state.dayNotes.recentDayNotes;
