import React from 'react';
import { useDispatch } from "react-redux";
import { FreeBusyStatus, WhereaboutsOption } from "../../../../../services/WhereaboutOptions";
import { updateMovement } from "../../../../../store/ducks/editMovements.duck";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { MovementImage } from "../../../atoms/MovementImage";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";
import { BodyRegular, BodyVerySmall } from "../../../atoms/fonts/Body";
import Colours from "../../../atoms/Colours";
import { Moment } from "moment";

export function StandardActivityItem(props: Props) {
  const {activeDay, iconPack, activity, activeUserId} = props;
  const dispatch = useDispatch();

  const updateSelectedDay = (e: any, option: WhereaboutsOption) => {
    e.stopPropagation();
    dispatch(updateMovement({selectedOption: option, locationId: 0, activeDay: activeDay?.format(DATE_FORMAT), activeUserId}));
  }

  return (<div className="activity" onClick={(e: any) => updateSelectedDay(e, activity)}>
    <MovementImage src={iconPackPath(iconPack, activity.image, FreeBusyStatus.FREE)} alt={activity.alt}/>
    <BodyRegular weight={600}>{activity.label}</BodyRegular>
    {props?.activity?.isPrivate && <BodyVerySmall colour={Colours.mildGrey} title={'This is only visible to you, line managers, and company admins.'}>Private</BodyVerySmall>}
  </div>)
}

interface Props {
  activeDay?: Moment;
  iconPack: any;
  activity: any;
  activeUserId?: string;
}
