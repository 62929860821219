import React from 'react';
import { Moment } from "moment/moment";
import { useDispatch } from "react-redux";
import { WhereaboutsOption } from "../../../../../services/WhereaboutOptions";
import { updateMovement } from "../../../../../store/ducks/editMovements.duck";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { MovementImage } from "../../../atoms/MovementImage";
import { BodyRegular } from "../../../atoms/fonts/Body";

export function MultipleLeaveItem(props: Props) {
  const {activeDay, activeUserId} = props;
  const dispatch = useDispatch();

  const onLeaveTypeSelected = (wo: WhereaboutsOption) => {
    dispatch(updateMovement({selectedOption: wo, locationId: 0, activeDay: activeDay?.format(DATE_FORMAT), activeUserId}));
  }

  const onClick = (e: any) => {
    e.stopPropagation();
    dispatch(openDialogWithPayload({
      payload: { onSelect: onLeaveTypeSelected },
      activeDialog: DialogIdentifiers.SelectLeaveTypeDialog
    }))
  }

  return (<div className="activity" onClick={onClick}>
    <MovementImage src={'/assets/icons/multiple-icon.svg'} alt={''}/>
    <BodyRegular weight={600}>Add Leave</BodyRegular>
  </div>)
}

interface Props {
  activeDay?: Moment;
  activeUserId?: string;
}
