import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveDay, selectFocusedUserId } from '../../../../../store/ducks/editMovements.duck';
import { Moment } from "moment";
import ActivityAdvanceHotDeskingOffice from "./ActivityAdvanceHotDeskingOffice";
import ActivitySimpleOffice from "./ActivitySimpleOffice";
import { selectOffices } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { selectAllSelectableWhereaboutsOptions } from "../../../../../store/ducks/whereaboutsOptions.duck";
import { CustomWhereaboutsType, HOLIDAY_ID, NON_WORKING_DAY } from "../../../../../services/WhereaboutOptions";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";
import { ActivityItem } from "./ActivityItem";
import { MultipleLeaveItem } from "./MultipleLeaveItem";

export default function ActivityList(props: { activeDate?: Moment }) {

  return (
    <div className="activityList">
      <ActivityListItems activeDate={props.activeDate} />
    </div>
  )
}

function ActivityListItems(props: { activeDate?: Moment }) {
  const activities = useSelector(selectAllSelectableWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);
  const activeDay = useSelector(selectActiveDay);
  const activeUserId = useSelector(selectFocusedUserId);

  const holidays = activities.filter(a => a.whereaboutsType === CustomWhereaboutsType.LEAVE || a.id === HOLIDAY_ID)

  if (holidays.length === 1) {
    return <>
      <WhereaboutsOffices activeDate={props.activeDate} />
      {activities.map((activity, key) => (
        <ActivityItem key={key}
                      activity={activity}
                      activeUserId={activeUserId}
                      activeDay={activeDay}
                      iconPack={iconPack} />
      ))}
    </>
  } else {
    const activitiesDiff = activities.filter(a => !holidays.includes(a) && a.id !== NON_WORKING_DAY);
    const nonWorkingDay = activities.filter(a => a.id === NON_WORKING_DAY);
    return <>
      <WhereaboutsOffices activeDate={props.activeDate} />
      {activitiesDiff.map((activity, key) => (
        <ActivityItem key={key}
                      activity={activity}
                      activeUserId={activeUserId}
                      activeDay={activeDay}
                      iconPack={iconPack} />
      ))}
      <MultipleLeaveItem activeUserId={activeUserId} activeDay={activeDay} />

      {nonWorkingDay.map((activity, key) => (
        <ActivityItem key={key}
                      activity={activity}
                      activeUserId={activeUserId}
                      activeDay={activeDay}
                      iconPack={iconPack} />
      ))}
    </>
  }
}

function WhereaboutsOffices(props: {activeDate: any}) {
  const {activeDate} = props;
  const offices = useSelector(selectOffices);

  if (offices.length > 0) {
    return <ActivityAdvanceHotDeskingOffice activeDate={activeDate} />
  } else {
    return <ActivitySimpleOffice activeDate={activeDate} offices={offices} />
  }
}


