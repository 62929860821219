import React from 'react';
import { useTranslation } from "react-i18next";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { Switch } from "../../../../../UI/atoms/Switch";
import styled from "styled-components/macro";

function key(path: string) {
  return `custom-whereabouts-page.${path}`;
}

export function DayNotesSetting(props: Props) {
  const {t} = useTranslation();
  return (
    <>
      <OptionRow>
        <Column>
          <BodyRegular weight={600}>{t(key('day-notes-title'))}</BodyRegular>
          <BodyRegular>{t(key('day-notes-description'))}</BodyRegular>
        </Column>
        <Switch value={props.dayNotesEnabled} onChange={props.onDayNotesEnabledChanged} />
      </OptionRow>
    </>
  )
}

interface Props {
  dayNotesEnabled: boolean;
  onDayNotesEnabledChanged: (value: boolean) => any;
}

const Column = styled.div`
  max-width: 80%;
`

const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`
