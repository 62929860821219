import React, { useState } from 'react';
import styled from "styled-components";
import Dialog from "../UI/molecules/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/ducks/dialog.duck";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import TextField from "../UI/atoms/TextField";
import { OutlineButton } from "../UI/atoms/buttons/OutlineButton";
import { BodyRegular, BodySmall } from "../UI/atoms/fonts/Body";
import { selectRecentDayNotes } from "../../store/ducks/dayNotes.duck";
import { RecentDayNotes } from "../../services/WhereaboutOptions";
import { Pill } from "../UI/atoms/Pill";
import Colours from "../UI/atoms/Colours";

export function DayNotesEntryDialog(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [note, setNote] = useState('');
  const recentDayNotes = useSelector(selectRecentDayNotes);

  const close = () => {
    dispatch(closeDialog());
  }

  const onSaveClicked = () => {
    props?.payload?.onSaveClicked(note);
    close();
  }

  const onQuickNoteSelected = (quickNote: RecentDayNotes) => {
    props?.payload?.onSaveClicked(quickNote.note);
    close();
  }

  return (
    <DialogWrapper isOpen={true} onClose={close}>
      <TextField onChange={setNote} value={note} label={'day-notes-dialog.add-note'} />
      {recentDayNotes.length > 0 && <>
        <BodyRegular weight={600}>{t('day-notes-dialog.recent-notes')}</BodyRegular>
        <RecentNotesRow>
          {recentDayNotes.map((dayNote: RecentDayNotes, key: number) => (<>
              <QuickSelectPill key={key} onClick={() => onQuickNoteSelected(dayNote)}>
                <BodySmall>{dayNote.note}</BodySmall>
              </QuickSelectPill>
            </>
          ))}
        </RecentNotesRow>
      </>}
      <OutlineButton style={{marginTop: 'auto'}} text={'button.save'} click={onSaveClicked} fullWidth={true} />
    </DialogWrapper>
  )
}

interface Props {
  payload: {
    date: Moment
    onSaveClicked: (note: string) => any;
  };
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 400px;
  }
  .dialog__contentContainer, .dialog__content {
    width: 300px;
    min-height: 410px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
`

export const QuickSelectPill = styled<any>(Pill)<any>`
  cursor: pointer;
  margin-right: 8px;
  &:hover {
    background-color: ${Colours.lightGrey};
  }
`

export const RecentNotesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`
