import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import auth from './ducks/auth.duck';
import dashboard from './ducks/dashboard.duck';
import editMovements from './ducks/editMovements.duck';
import addMemberDialog from './ducks/addMemberDialog.duck';
import forgotPassword from './ducks/forgotPassword.duck';
import addTeamDialog from './ducks/addTeamDialog.duck';
import registration from './ducks/registration.duck';
import onboarding from './ducks/onboarding.duck';
import management from './ducks/management.duck';
import notification from './ducks/notification.duck';
import general from './ducks/general.duck';
import companyMovements from './ducks/companyMovements.duck';
import setupCompany from './ducks/setupCompany.duck';
import loginPage from '../components/pages/login/login.duck';
import permissionNeededDialog from '../components/dialogs/permission-needed-dialog/permissionNeeded.duck';
import deleteTeamDialog from '../components/dialogs/delete-team-dialog/deleteTeamDialog.duck';
import deleteCompanyDialog from '../components/dialogs/delete-company-dialog/deleteCompanyDialog.duck';
import config from './ducks/config.duck';
import editCompany from '../components/pages/settings/pages/edit-company-page/editCompany.duck';
import holidayBooking from './ducks/holidayBooking.duck';
import holidays from './ducks/holidays.duck';
import companyStructure from './ducks/companyStructure.duck';
import outlookSync from './ducks/outlookSync.duck';
import payments from './ducks/payments.duck';
import wallPlanner from '../components/pages/wall-planner/wallPlanner.duck';
import dialog from './ducks/dialog.duck';
import advanceHotDeskingSetup from './ducks/advanceHotDeskingSetup.duck';
import deskReservation from './ducks/deskReservation.duck';
import officeUsage from './ducks/officeUsage.duck';
import lineReportsWhereabouts from './ducks/LineReportsWhereabouts.duck';
import editOfficeLayout from './ducks/editOfficeLayout.duck';
import weeklyDeskAvailability from './ducks/weeklyDeskAvailability.duck';
import officeView from './ducks/officeView.duck';
import dayNotes from './ducks/dayNotes.duck';
import deskBooking from './ducks/deskBooking.duck';
import editUser from './ducks/editUser.duck';
import approvalRequests from './ducks/approvalRequests.duck';
import userTags from './ducks/userTags.duck';
import userManagement from './ducks/userManagement.duck';
import whereaboutsOptions from './ducks/whereaboutsOptions.duck';
import holidaysV2 from './ducks/holidays-v2.duck';
import editDesk from './ducks/editDesk.duck';
import followingTeamsAndTags from './ducks/followingTeamsAndTags.duck';
import keyResponsibilitiesReporting from "../components/pages/reporting/key-responsibilities-report/key-responsibilities.duck";
import roomFinder from "../components/pages/meeting-rooms/models/room-finder.duck";
import meetingRoom from "../components/pages/meeting-rooms/models/meeting-room.duck";
import meetingRoomSettings from "../components/pages/settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import manageUsers from "../components/pages/settings/pages/manage-users-page-v2/ducks/manage-users.duck";
import addUsers from "../components/pages/settings/pages/manage-users-page-v2/ducks/add-users.duck";
import manageDesks from "../components/pages/company-movements/ducks/manageDesks.duck";
import teamMovements from "../components/pages/team-movements/models/team-movements.duck";
import wallPlannerHoverBox from "../components/pages/wall-planner/data/WallPlannerHoverBox.duck";
import holidayReports from "../components/pages/holidays-v2/models/holiday-reports.duck";
import carParkingMapView from "../components/pages/company-movements/ducks/carParkingMapView.duck";
import visitorBooking from "../components/pages/company-movements/components/visitor-booking/data/visitorBooking.duck";
import departments from "../components/pages/departments/data/departments.duck";
import holidayAllowances from "../components/pages/holidays-v2/models/holiday-allowances.duck";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  dashboard,
  editMovements,
  addMemberDialog,
  forgotPassword,
  addTeamDialog,
  registration,
  onboarding,
  management,
  notification,
  general,
  companyMovements,
  setupCompany,
  loginPage,
  permissionNeededDialog,
  deleteTeamDialog,
  deleteCompanyDialog,
  config,
  editCompany,
  holidayBooking,
  holidays,
  companyStructure,
  outlookSync,
  payments,
  wallPlanner,
  dialog,
  advanceHotDeskingSetup,
  deskReservation,
  officeUsage,
  editOfficeLayout,
  weeklyDeskAvailability,
  officeView,
  deskBooking,
  editUser,
  approvalRequests,
  userTags,
  userManagement,
  whereaboutsOptions,
  holidaysV2,
  editDesk,
  followingTeamsAndTags,
  keyResponsibilitiesReporting,
  roomFinder,
  meetingRoom,
  meetingRoomSettings,
  manageUsers,
  manageDesks,
  teamMovements,
  wallPlannerHoverBox,
  addUsers,
  holidayReports,
  carParkingMapView,
  visitorBooking,
  departments,
  lineReportsWhereabouts,
  holidayAllowances,
  dayNotes,
});

export default createRootReducer;
