import React from 'react';
import Colours from "../../../atoms/Colours";
import styled from "styled-components/macro";
import { BodySmall } from "../../../atoms/fonts/Body";
import { useDispatch } from "react-redux";
import { openLocationDialog, openLocationDialogWithOfficeId } from "../../../../../store/ducks/deskReservation.duck";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";

interface Props {
  office?: OfficeEntity;
  className?: string;
}

export function EditHotDeskingButton(props: Props) {
  const dispatch = useDispatch();

  const editLocation = (e: any) => {
    e.stopPropagation();
    if (props.office) {
      dispatch(openLocationDialogWithOfficeId(props.office?.parentId || props.office?.id));
    } else {
      dispatch(openLocationDialog());
    }
  }

  return (
    <ChangeOfficeButton className={props.className ?? ''}
                        colour={Colours.darkGrey}
                        weight={600}
                        data-test={'change-office-button'}
                        onClick={(e: any) => editLocation(e)}>Change</ChangeOfficeButton>
  )
}

const ChangeOfficeButton = styled<any>(BodySmall)`
  padding: 8px;
  border: 1px solid ${Colours.mildGrey};
  border-radius: 4px;
`
