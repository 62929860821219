import { createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';

export enum DialogIdentifiers {
  StripeSuccess, StripeFailure, ChooseLocation, SignUpTermsDialog,
  CreateTagDialog, EditTagDialog, EditTagNameDialog, WhereaboutsModifiedDialog, AddCustomWhereabouts,
  DeleteOfficeEntityDialog, DeleteDeskEntityDialog, AzureCalendarSyncDisabledDialog,
  EditDeskDialog, EditUsersInTagDialog, ManageFavouritesDialog, WeeklyNotes,
  AzurePermissionsRequired, BookMeetingRoom, EditBookMeetingRoom,
  AdvanceMeetingRoomBookingDialog, RepeatScheduleDialog, SavedWhereaboutsErrors,
  AssignToOffice, AssignToTeam, AssignToDepartment, AssignToWorkgroup, AddMultipleUsersInfo, AssignDesksToTeam,
  AssignDesksToIndividual, AssignDesksToWorkgroup, DeleteMeetingRoomConfirm, ResetDesks,
  CreateTeamDialog, LinkTeamOrGroupToOfficeDialog, NewUserOnboardingDialog, ChangeOfficeDialog,
  ChangeCalendarDateDialog, EditParkingSpaceDialog, AddVisitorDialog, EditDepartmentDialog,
  UnableToBookDesksErrorDialog, CannotEditApprovedHoliday, SelectLeaveTypeDialog, CancelApproveLeaveDialog,
  UsageLimitReachedDialog, SideOfDaySelectorDialog, VisitorBookingFilterDialog, DayNotesEntryDialog
}

export interface DialogState {
  activeDialog: DialogIdentifiers | null;
  payload?: any;
  scrollPosition?: number;
}

export const initialDialogState: DialogState = {
  activeDialog: null,
  payload: undefined,
  scrollPosition: undefined,
}

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: initialDialogState,
  reducers: {
    reset: () => initialDialogState,
    openDialog: (state, action) => ({...state, activeDialog: action.payload}),
    openDialogWithPayload: (state, action) => ({
      ...state, payload: action.payload.payload, activeDialog: action.payload.activeDialog
    }),
    closeDialog: (state) => ({...state, activeDialog: null}),
    setScrollPosition: (state, action) => ({...state, scrollPosition: action.payload}),
  },
  extraReducers: {}
})

export const {
  reset,
  openDialog,
  openDialogWithPayload,
  closeDialog,
  setScrollPosition,
} = dialogSlice.actions;
export default dialogSlice.reducer;

// Selectors
export const selectActiveDialog = (state: AppState) => state.dialog.activeDialog;
export const selectPayload = (state: AppState) => state.dialog.payload;
export const selectScrollPosition = (state: AppState) => state.dialog.scrollPosition;
