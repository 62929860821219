import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import StripeSuccessDialog from "./stripe/StripeSuccessDialog";
import {
  DialogIdentifiers,
  selectActiveDialog,
  selectPayload,
  selectScrollPosition,
  setScrollPosition
} from "../../../store/ducks/dialog.duck";
import SignUpTermsDialog from "../../dialogs/SignUpTermsDialog";
import ChooseLocationDialog from "../../dialogs/choose-location-dialog/ChooseLocationDialog";
import CreateTagDialog from "../../dialogs/CreateTagDialog";
import { WhereaboutsModifiedDialog } from "../../dialogs/WhereaboutsModifiedDialog";
import StripeFailedDialog from "./stripe/StripeFailedDialog";
import { AddCustomWhereaboutsDialog } from '../../dialogs/custom-whereabouts-dialog/AddCustomWhereaboutsDialog';
import DeleteOfficeEntityDialog from "../../dialogs/DeleteOfficeEntityDialog";
import DeleteDeskEntityDialog from "../../dialogs/DeleteDeskEntityDialog";
import { AzureCalendarSyncDisabledDialog } from "../../dialogs/AzureCalendarSyncDisabledDialog";
import EditDeskDialog from "../../dialogs/EditDeskDialog";
import EditUsersInTagDialog from '../../dialogs/EditUsersInTagDialog';
import ManageFavouritesDialog from "../../dialogs/ManageFavouritesDialog";
import { WeeklyNotesDialog } from "../../dialogs/weekly-notes/WeeklyNotesDialog";
import { AzurePermissionsRequired } from "../../dialogs/AzurePermissionsRequired";
import { MeetingRoomBookingDialog } from "../../pages/meeting-rooms/dialogs/MeetingRoomBookingDialog";
import { EditMeetingRoomBookingDialog } from "../../pages/meeting-rooms/dialogs/EditMeetingRoomBookingDialog";
import { AdvanceMeetingRoomBookingDialog } from "../../pages/meeting-rooms/dialogs/AdvanceMeetingRoomBookingDialog";
import { RepeatScheduleDialog } from "../../dialogs/repeat-schedule-dialog/RepeatScheduleDialog";
import { SavedWhereaboutsErrors } from "../../dialogs/saved-whereabouts-errors/SavedWhereaboutsErrors";
import AssignToOfficeDialog from "../../pages/settings/pages/manage-users-page-v2/dialogs/AssignToOfficeDialog";
import AssignToTeamDialog from "../../pages/settings/pages/manage-users-page-v2/dialogs/AssignToTeamDialog";
import AssignToWorkGroupDialog from "../../pages/settings/pages/manage-users-page-v2/dialogs/AssignToWorkGroupDialog";
import AddMultipleUsersDialog from "../../dialogs/AddMultipleUsersDialog";
import EditTagDialog from "../../dialogs/workgroup-dialogs/EditTagDialog";
import EditTagNameDialog from "../../dialogs/workgroup-dialogs/EditTagNameDialog";
import { AssignDesksToTeamDialog } from "../../pages/company-movements/dialogs/AssignDesksToTeamDialog";
import { AssignDesksToIndividualDialog } from "../../pages/company-movements/dialogs/AssignDesksToIndividualDialog";
import { AssignDesksToWorkgroupDialog } from "../../pages/company-movements/dialogs/AssignDesksToWorkgroupDialog";
import { DeleteMeetingRoomConfirmDialog } from "../../pages/meeting-rooms/dialogs/DeleteMeetingRoomConfirmDialog";
import { ResetDesksDialog } from "../../pages/meeting-rooms/dialogs/ResetDesksDialog";
import AddTeamDialog from "../../dialogs/add-team-dialog/AddTeamDialog";
import {
  LinkTeamOrWorkgroupToOfficeDialog
} from "../../pages/company-movements/dialogs/LinkTeamOrWorkgroupToOfficeDialog";
import { NewUserOnboardingDialog } from "../../new-user-onboarding-dialog/NewUserOnboardingDialog";
import ChangeOfficeDialog from "../../pages/company-movements/dialogs/ChangeOfficeDialog";
import ChangeCalendarDateDialog from "../../pages/company-movements/dialogs/ChangeCalendarDateDialog";
import { EditCarParkingSpaceDialog } from "../../dialogs/car-parking-plan-dialogs/EditCarParkingSpaceDialog";
import { AddVisitorDialog } from "../../pages/company-movements/components/visitor-booking/AddVisitorDialog";
import { EditDepartmentDialog } from "../../pages/departments/EditDepartmentDialog";
import { UnableToBookDesksErrorDialog } from "../../dialogs/saved-whereabouts-errors/UnableToBookDesksErrorDialog";
import { CannotEditApprovedHoliday } from "../../dialogs/saved-whereabouts-errors/CannotEditApprovedHoliday";
import AssignToDepartmentDialog from "../../pages/settings/pages/manage-users-page-v2/dialogs/AssignToDepartmentDialog";
import { SelectLeaveTypeDialog } from "../../dialogs/SelectLeaveTypeDialog";
import { CancelApprovedLeaveDialog } from "../../pages/holidays-v2/approvals/dialogs/CancelApprovedLeaveDialog";
import { UsageLimitReachedDialog } from "../../dialogs/usage-limit-dialog/UsageLimitReachedDialog";
import { SideOfDaySelectorDialog } from "../../dialogs/side-of-day-dialog/SideOfDaySelectorDialog";
import {
  VisitorBookingFilterDialog
} from "../../pages/company-movements/components/visitor-booking/VisitorBookingFilterDialog";
import { DayNotesEntryDialog } from "../../dialogs/DayNotesEntryDialog";


export function DynamicDialogRenderer() {
  const activeDialog = useSelector(selectActiveDialog);
  const scrollPosition = useSelector(selectScrollPosition);
  const payload = useSelector(selectPayload);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeDialog && scrollPosition) {
      window.scrollTo(0, scrollPosition);
      dispatch(setScrollPosition(null));
    }
  }, [dispatch, scrollPosition, activeDialog]);

  switch (activeDialog) {
    case DialogIdentifiers.StripeSuccess:
      return <StripeSuccessDialog/>;
    case DialogIdentifiers.StripeFailure:
      return <StripeFailedDialog/>;
    case DialogIdentifiers.ChooseLocation:
      return <ChooseLocationDialog payload={payload} />;
    case DialogIdentifiers.SignUpTermsDialog:
      return <SignUpTermsDialog/>;
    case DialogIdentifiers.CreateTagDialog:
      return <CreateTagDialog payload={payload}/>;
    case DialogIdentifiers.EditTagDialog:
      return <EditTagDialog payload={payload}/>;
    case DialogIdentifiers.WhereaboutsModifiedDialog:
      return <WhereaboutsModifiedDialog/>;
    case DialogIdentifiers.AddCustomWhereabouts:
      return <AddCustomWhereaboutsDialog/>;
    case DialogIdentifiers.DeleteOfficeEntityDialog:
      return <DeleteOfficeEntityDialog payload={payload}/>;
    case DialogIdentifiers.DeleteDeskEntityDialog:
      return <DeleteDeskEntityDialog payload={payload}/>;
    case DialogIdentifiers.AzureCalendarSyncDisabledDialog:
      return <AzureCalendarSyncDisabledDialog />;
    case DialogIdentifiers.EditDeskDialog:
      return <EditDeskDialog payload={payload} />;
    case DialogIdentifiers.EditUsersInTagDialog:
      return <EditUsersInTagDialog payload={payload} />;
    case DialogIdentifiers.ManageFavouritesDialog:
      return <ManageFavouritesDialog />;
    case DialogIdentifiers.WeeklyNotes:
      return <WeeklyNotesDialog payload={payload} />;
    case DialogIdentifiers.AzurePermissionsRequired:
      return <AzurePermissionsRequired />;
    case DialogIdentifiers.BookMeetingRoom:
      return <MeetingRoomBookingDialog payload={payload} />;
    case DialogIdentifiers.EditBookMeetingRoom:
      return <EditMeetingRoomBookingDialog payload={payload} />;
    case DialogIdentifiers.AdvanceMeetingRoomBookingDialog:
      return <AdvanceMeetingRoomBookingDialog payload={payload} />;
    case DialogIdentifiers.RepeatScheduleDialog:
      return <RepeatScheduleDialog payload={payload} />;
    case DialogIdentifiers.SavedWhereaboutsErrors:
      return <SavedWhereaboutsErrors payload={payload} />;
    case DialogIdentifiers.AssignToOffice:
      return <AssignToOfficeDialog payload={payload} />;
    case DialogIdentifiers.AssignToTeam:
      return <AssignToTeamDialog payload={payload} />;
    case DialogIdentifiers.AssignToDepartment:
      return <AssignToDepartmentDialog payload={payload} />;
    case DialogIdentifiers.AssignToWorkgroup:
      return <AssignToWorkGroupDialog payload={payload} />;
    case DialogIdentifiers.AddMultipleUsersInfo:
      return <AddMultipleUsersDialog />;
    case DialogIdentifiers.EditTagNameDialog:
      return <EditTagNameDialog payload={payload} />;
    case DialogIdentifiers.AssignDesksToTeam:
      return <AssignDesksToTeamDialog />;
    case DialogIdentifiers.AssignDesksToIndividual:
      return <AssignDesksToIndividualDialog />;
    case DialogIdentifiers.AssignDesksToWorkgroup:
      return <AssignDesksToWorkgroupDialog />;
    case DialogIdentifiers.DeleteMeetingRoomConfirm:
      return <DeleteMeetingRoomConfirmDialog payload={payload} />;
    case DialogIdentifiers.ResetDesks:
      return <ResetDesksDialog />;
    case DialogIdentifiers.CreateTeamDialog:
      return <AddTeamDialog />;
    case DialogIdentifiers.LinkTeamOrGroupToOfficeDialog:
      return <LinkTeamOrWorkgroupToOfficeDialog />;
    case DialogIdentifiers.NewUserOnboardingDialog:
      return <NewUserOnboardingDialog />;
    case DialogIdentifiers.ChangeOfficeDialog:
      return <ChangeOfficeDialog />;
    case DialogIdentifiers.ChangeCalendarDateDialog:
      return <ChangeCalendarDateDialog />;
    case DialogIdentifiers.EditParkingSpaceDialog:
      return <EditCarParkingSpaceDialog payload={payload} />;
    case DialogIdentifiers.AddVisitorDialog:
      return <AddVisitorDialog payload={payload} />;
    case DialogIdentifiers.EditDepartmentDialog:
      return <EditDepartmentDialog payload={payload} />;
    case DialogIdentifiers.UnableToBookDesksErrorDialog:
      return <UnableToBookDesksErrorDialog payload={payload} />;
    case DialogIdentifiers.CannotEditApprovedHoliday:
      return <CannotEditApprovedHoliday />;
    case DialogIdentifiers.SelectLeaveTypeDialog:
      return <SelectLeaveTypeDialog payload={payload} />;
    case DialogIdentifiers.CancelApproveLeaveDialog:
      return <CancelApprovedLeaveDialog payload={payload} />;
    case DialogIdentifiers.UsageLimitReachedDialog:
      return <UsageLimitReachedDialog payload={payload} />;
    case DialogIdentifiers.SideOfDaySelectorDialog:
      return <SideOfDaySelectorDialog payload={payload} />;
    case DialogIdentifiers.VisitorBookingFilterDialog:
      return <VisitorBookingFilterDialog />;
    case DialogIdentifiers.DayNotesEntryDialog:
      return <DayNotesEntryDialog payload={payload} />;
    default:
      return null;
  }
}
