import { TeamEntity } from "./team.models";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { Tag } from "./tags.models";
import { UNKNOWN_OPTION, WhereaboutsOption } from "../services/WhereaboutOptions";
import { ApprovalState } from "./approval-requests.models";


export class TeamWithMovements {
  constructor(public team: TeamEntity,
              public visible: boolean = false,
              public movementsLoaded: boolean = false,
              public movements?: MemberMovements[],
              public userDesks?: any,
              public restricted?: boolean,
              public hasWeekNotes?: boolean) {
  }
}

export class TagWithMovements {
  constructor(public tag: Tag,
              public visible: boolean = false,
              public movementsLoaded: boolean = false,
              public movements?: MemberMovements[],
              public userDesks?: any,
              public restricted?: boolean) {
  }
}

export enum Period {
  AllDay = 'ALL',
  AM = 'AM',
  PM = 'PM'
}

export enum SideOfDay {
  AllDay = 'AllDay',
  AM = 'AM',
  PM = 'PM'
}

export function toSideOfDay(period: Period) {
  switch (period) {
    case Period.AM: return SideOfDay.AM;
    case Period.PM: return SideOfDay.PM;
    default: return SideOfDay.AllDay;
  }
}

export interface MemberMovements {
  firstName: string;
  id: string;
  teamId: string;
  lastName: string;
  fullName: string;
  fireWarden: boolean;
  firstAider: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
  carParking?: UsersCarParkBooking[];
  whereabouts: UsersWhereaboutsDay[];
  departmentId?: number;
  dateFrom: string;
  dateTo: string;
  birthdayDay?: number;
  birthdayMonth?: number;

  saving?: boolean;
}

export interface UsersCarParkBooking {
  id: number;
  am: boolean;
  pm: boolean;
  officeId: number;
  period: Period;
  date: string;
}

export interface SimpleUserWithLocation {
  userId: string;
  firstName: string;
  lastName: string;
  officeId: number;
  pmOfficeId: number;
  fireWarden: boolean;
  firstAider: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
}


export interface OfficeWithUsers {
  office?: OfficeEntity;
  area?: OfficeEntity;
  users: SimpleUserWithLocation[];
}

/**
 * Deprecated
 */
export interface MovementDay {
  movementId: string;
  locationId: number;
  userId: string;
  localDate: string;
  amStatus: string;
  pmStatus: string;
  amStatusId: number;
  pmStatusId: number;
}

export interface WhereaboutsDay {
  whereaboutsId: string;
  userId: string;
  date: string;
  localDate: string;
  amLocationId: number;
  pmLocationId: number;
  amStatus: string;
  pmStatus: string;
  amGeneralParking: boolean;
  pmGeneralParking: boolean;
}

export interface WhereaboutsDayV2 {
  id: number;
  date: string;
  userId: string;
  firstName: string;
  lastName: string;
  amDeskId: number;
  amDeskLabel?: string;
  amOfficeId: number;
  amStatusId: number;
  pmDeskId: number;
  pmDeskLabel?: string;
  pmOfficeId: number;
  pmStatusId: number;
  fireWarden: boolean;
  firstAider: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
  statusAllDay?: boolean;
  teamId?: string;
  teamName?: string;

  amOption?: WhereaboutsOption;
  pmOption?: WhereaboutsOption;

  amApproved?: ApprovalState;
  pmApproved?: ApprovalState;
}

export interface UsersWhereaboutsDay {
  id: number;
  userId: string;
  date: string;
  amStatusId: number;
  pmStatusId: number;
  amOfficeId: number;
  pmOfficeId: number;
  amDeskId: number;
  pmDeskId: number;
  amParkingSpaceId: number;
  pmParkingSpaceId: number;
  amDeskLabel: string;
  pmDeskLabel: string;
  amParkingSpaceLabel: string;
  pmParkingSpaceLabel: string;
  amGeneralParking: boolean;
  pmGeneralParking: boolean;
  keyHolder: boolean;
  firstAider: boolean;
  fireWarden: boolean;
  mentalHealthAider: boolean;
  firstName: string;
  lastName: string;
  teamId: string;
  teamName: string;
  departmentId: string;
  isWhereaboutsSaving?: boolean;
  whereaboutsUpdateFailed?: boolean;
  nextAmStatusId?: number;
  nextPmStatusId?: number;
  amApproved?: ApprovalState;
  pmApproved?: ApprovalState;
  dayNote?: string;
}

export function emptyWhereaboutsDay(date: string, userId: string) {
  return {
    id: 0,
    userId: userId,
    date: date,
    amStatusId: UNKNOWN_OPTION.id,
    pmStatusId: UNKNOWN_OPTION.id,
    amOfficeId: 0,
    pmOfficeId: 0,
    amDeskId: 0,
    pmDeskId: 0,
    amParkingSpaceId: 0,
    pmParkingSpaceId: 0,
    amDeskLabel: '',
    pmDeskLabel: '',
    amParkingSpaceLabel: '',
    pmParkingSpaceLabel: '',
    amGeneralParking: false,
    pmGeneralParking: false,
    keyHolder: false,
    firstAider: false,
    fireWarden: false,
    mentalHealthAider: false,
    firstName: '',
    lastName: '',
    teamId: '',
    teamName: '',
    departmentId: '',
  }
}

export function sortWhereaboutsByDate(a: UsersWhereaboutsDay, b: UsersWhereaboutsDay) {
  return a.date > b.date ? 1 : -1;
}
