import React from 'react';
import { Moment } from "moment/moment";
import { StandardActivityItem } from "./StandardActivityItem";
import { DayNotesActivityItem } from "./DayNotesActivityItem";

export function ActivityItem(props: ActivityItemProps) {
  const {activeDay, iconPack, activity, activeUserId} = props;
  if (activity.dayNotesEnabled) {
    return <DayNotesActivityItem iconPack={iconPack} activity={activity} activeUserId={activeUserId} activeDay={activeDay} />
  } else {
    return <StandardActivityItem iconPack={iconPack} activity={activity} activeUserId={activeUserId} activeDay={activeDay} />
  }
}

interface ActivityItemProps {
  activeDay?: Moment;
  iconPack: any;
  activity: any;
  activeUserId?: string;
}
